// import React, { useEffect, useState } from 'react';
// import Form from 'react-bootstrap/Form'; 

// const SearchBar = ({setSearchValue,hide}) => {
//     const [searchText, setSearchText] = useState('');
//     const timeout = 600; 

//     useEffect(() => {
//         const handler = setTimeout(() => {
//             console.log('Searching for:', searchText);
//             setSearchValue(searchText)
//         }, timeout);

//         return () => {
//             clearTimeout(handler);
//         };
//     }, [searchText]); 

//     const handleSearchChange = (e) => {
//         setSearchText(e.target.value);
//     };

//     return (
//         <div className={`user-pro-search ${hide && 'inner-search-bar'}`}>
//             <Form.Control
//                 type="search"
//                 placeholder="Search"
//                 className="form-control w-100"
//                 value={searchText}
//                 onChange={handleSearchChange}
//             />
//             {/* <img src={require("../../Assets/Images/search-icon.svg").default} alt="search icon" /> */}
//         </div>
//     );
// };

// export default SearchBar;


import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";

const SearchBar = ({ setSearchValue, hide, searchValue, handleDidYouMeanClick }) => {
  const [searchText, setSearchText] = useState(searchValue || "");
  const timeout = 600;

  const browseDidYouMeanResult = useSelector((state) => state?.browseSlice?.didYouMeanResult)
  const browseList = useSelector((state) => state?.browseSlice?.data)

  useEffect(() => {
    if (searchValue !== searchText) {
      setSearchText(searchValue || "");
    }
  }, [searchValue]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchValue(searchText);
    }, timeout);

    return () => {
      clearTimeout(handler);
    };
  }, [searchText, setSearchValue]);

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setSearchValue(searchText);
    }
  };

  return (
    <div className={`user-pro-search ${hide && "inner-search-bar"}`}>
      <Form.Control
        type="search"
        placeholder="Search"
        className="form-control w-100"
        value={searchText}
        onChange={handleSearchChange}
        onKeyPress={handleKeyPress}
      />

{browseDidYouMeanResult?.trim() && (
  <div
    className="did-you-mean"
    style={{ margin: "2px 0px", cursor: "pointer" }}
    onClick={handleDidYouMeanClick}
  >
    Did you mean: <strong>{browseDidYouMeanResult}</strong>
  </div>
)}
    </div>
  );
};

export default SearchBar;
