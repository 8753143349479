
import "../App.css";
import ShortContainer from "../Components/ShortContainer";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Layout from "../Components/Layout/Layout";
import "react-loading-skeleton/dist/skeleton.css";
import TopHeader from "../Components/TopHeader";
import { Decryptedid } from "../Util/BcruptEncyptid";
import { useDispatch, useSelector } from "react-redux";
import { browseCategoryDetailAction, collectionVideosAction,resetBrowseAction } from "../Redux/Action/BrowseAction";
import LoaderComponent from "../Components/common/LoaderComponent";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { getQueryParams } from "../Util/HelperFunction";

const getVideoId = (search) => {
  const params = new URLSearchParams(search);
  return params.get('videoId');
};

function CollectionShorts() {
  const Location = useLocation();
  const queryParams = getQueryParams(Location?.search);
  const { selectedGroup, categories } = queryParams;
  const { id, videoIds } = useParams();
  const handle = useFullScreenHandle();
  const idval = Decryptedid(atob(id));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fullscreenState, setFullscreenState] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);

  const handleGoBack = () => {
    navigate(-1)
  };

  const playbackDetail = useSelector(
    (state) => state?.browseSlice?.collectionVideosData
  );

  const videoId = getVideoId(Location?.search);

  const fetchVideos = async (pageNumber, limit = 30) => {
    const FilterPayload = {
      searchValue: searchValue,
      id: idval,
      videoId: videoId || videoIds,
      // page: pageNumber,
      // limit
    };

    setIsLoading(true);
    dispatch(resetBrowseAction());
    await dispatch(collectionVideosAction(FilterPayload));
    setIsLoading(false);
  };

  useEffect(() => {
    const loadInitialVideos = async () => {
      setIsLoading(true);
      dispatch(resetBrowseAction());
      await fetchVideos(0);
      setIsLoading(false);
    };

    loadInitialVideos();

    return () => {
      dispatch(resetBrowseAction());
    };
  }, [dispatch, searchValue, idval, videoId]);

  const handleToggleFullscreen = () => {
    if (fullscreenState) {
      handle.exit();
      setFullscreenState(false);
    } else {
      handle.enter();
      setFullscreenState(true);
    }
  };

  useEffect(() => {
    const onFullscreenChange = () => {
      if (!document.fullscreenElement) {
        setFullscreenState(false);
      }
    };

    document.addEventListener("fullscreenchange", onFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", onFullscreenChange);
    };
  }, []);

  return (
    <Layout>
      <>
        <TopHeader
          setSearchValue={setSearchValue}
          groupData={[]}
          type="PlayBack"
        />

        <Container fluid>
          <div className="filter-tab" onClick={handleGoBack}>
            <Link to="" className="back-btn">
              <svg
                width="9"
                height="14"
                viewBox="0 0 9 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 1L2 7L8 13"
                  stroke="black"
                  strokeOpacity="0.8"
                  strokeWidth="2"
                />
              </svg>{" "}
              Back
            </Link>
          </div>
        </Container>

        <div>
          <FullScreen handle={handle}>
            <main
              className={` ${fullscreenState ? 'main fullScreenVideo' : 'main'}`}
            >
              <ShortContainer
                data={playbackDetail?.video}
                fullscreenState={fullscreenState}
                handleToggleFullscreen={handleToggleFullscreen}
                fetchVideos={fetchVideos}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                isLoading={isLoading}
              />
            </main>
          </FullScreen>
        </div>
      </>
    </Layout>
  );
}

export default CollectionShorts;
