import React, { useRef, useState, useEffect, useCallback } from "react";
import Carousel from "react-bootstrap/Carousel";
import Short from "./Short";
import SideActions from "./SideActions";
import { Col, Row } from "react-bootstrap";
import SaveToCollection from "../modals/SaveToCollection";

// Custom Spinner Component
const Spinner = () => (
  <div className="spinner">
    <div className="spinner-inner"></div>
    <style jsx>{`
      .spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
        width: 100%;
        position: absolute;
        bottom: -23px;
        right: -67px;
      }
      .spinner-inner {
        border: 4px solid #f3f3f3;
        border-top: 4px solid #3498db;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 1s linear infinite;
      }
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `}</style>
  </div>
);

function ShortContainer({ data, fullscreenState, handleToggleFullscreen, fetchVideos, pageNumber, setPageNumber, isLoading }) {
  const shortContainerRef = useRef();
  const currentPlayingVideoRef = useRef(null); // Track the currently playing video
  const [activeIndex, setActiveIndex] = useState({});
  const [activeVideoIndex, setActiveVideoIndex] = useState({});
  const [isManuallyPaused, setIsManuallyPaused] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedSave, setSelectedSave] = useState(null);
  const [isSubtitle, setIsSubtitle] = useState(true);
  const [displayedVideos, setDisplayedVideos] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const handleShow = (ele) => {
    setShow(true);
    setSelectedSave(ele);
  };

  const handleClose = () => {
    setShow(false);
    setSelectedSave(null);
  };

  const handleSelect = (carouselIndex, selectedIndex, e) => {
    if (e && e.type === "click") {
      e.preventDefault();
      setActiveIndex((prev) => ({
        ...prev,
        [carouselIndex]: selectedIndex,
      }));
      setActiveVideoIndex((prev) => ({
        ...prev,
        [carouselIndex]: selectedIndex,
      }));
    }
  };

  useEffect(() => {
    if (Array.isArray(data) && data.length > 0) {
      setDisplayedVideos(data.slice(0, 5)); // Initial batch of 5 videos
    }
  }, [data]);

  // Debounced scroll handler
  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  };

  const handleScroll = useCallback(
    debounce(() => {
      setIsManuallyPaused(false)
      if (!shortContainerRef.current || isLoading || isLoadingMore) return;

      const { scrollTop, scrollHeight, clientHeight } = shortContainerRef.current;
      const isNearBottom = scrollHeight - scrollTop - clientHeight < 200;

      if (isNearBottom) {
        if (displayedVideos.length < data.length) {
          setIsLoadingMore(true);
          setTimeout(() => {
            setDisplayedVideos((prev) => [
              ...prev,
              ...data.slice(prev.length, prev.length + 5),
            ]);
            setIsLoadingMore(false);
          }, 500);
        } else {
          setPageNumber((prevPage) => {
            const nextPage = prevPage + 1;
            fetchVideos(nextPage, 30);
            return nextPage;
          });
        }
      }
    }, 200),
    [data, displayedVideos, isLoading, isLoadingMore, fetchVideos, setPageNumber]
  );

  useEffect(() => {
    const container = shortContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, [handleScroll]);

  const handleArrowUp = () => {
    if (shortContainerRef.current) {
      shortContainerRef.current.scrollTo({
        top: shortContainerRef.current.scrollTop - window.innerHeight,
        behavior: "smooth",
      });
    }
  };

  const handleArrowDown = () => {
    if (shortContainerRef.current && !isLoading && !isLoadingMore) {
      shortContainerRef.current.scrollTo({
        top: shortContainerRef.current.scrollTop + window.innerHeight,
        behavior: "smooth",
      });
    }
  };


  const formatDescription = (text) => {
    if (!text) return "";
  
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex).map((part, index) => {
      if (part.match(urlRegex)) {
        return (
          <a
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            style={{ display: "block", wordBreak: "break-all", color: "#007bff" }}
          >
            {part}
          </a>
        );
      }
      return <span key={index}>{part}</span>;
    });
  };

  return (
    <>
      <section ref={shortContainerRef} className="short-container">
        <style jsx>{`
          .short-container {
            scroll-behavior: smooth;
            overflow-y: auto;
            height: 100vh;
          }
          .reel {
            transition: opacity 0.3s ease;
          }
        `}</style>
        {Array.isArray(displayedVideos) &&
          displayedVideos.map((short, carouselIndex) => (
            <div
              className={` ${fullscreenState ? "fullScreenVideoReel" : "reel"}`}
              key={short._id} // Use a unique key to prevent unmounting
            >
              <div className="row main-reel-view">
                {fullscreenState ? null : (
                  <div className="col-md-5">
                    <div className="video-content">
                      <h2>{short?.title}</h2>
                      <p>{short?.categoryId?.title}</p>
                      {/* <h6>{short?.description}</h6> */}
                      <h6>{formatDescription(short?.description)}</h6>
                      {short?.isSensitive && (
                        <span>
                          <img
                            alt=""
                            src={require("../Assets/Images/Info.svg").default}
                          />{" "}
                          Sensitive content warning
                        </span>
                      )}
                    </div>
                  </div>
                )}

                <div className={` ${fullscreenState ? "col-md-10" : "col-md-7"}`}>
                  <div className="reel-video">
                    <Carousel
                      slide={false}
                      activeIndex={activeIndex[carouselIndex] || 0}
                      onSelect={(selectedIndex, e) =>
                        handleSelect(carouselIndex, selectedIndex, e)
                      }
                      controls={short?.isMultiple || false}
                      indicators={short?.isMultiple || false}
                    >
                      {short.videoParts?.map((video, index) => (
                        <Carousel.Item
                          key={`${short._id}-${index}`} // Unique key per video part
                          className={` ${
                            fullscreenState ? "video-sec fullscreenStateAnother" : "video-sec"
                          }`}
                        >
                          <Short
                            handleToggleFullscreen={handleToggleFullscreen}
                            shortContainerRef={shortContainerRef}
                            short={video}
                            index={index}
                            activeIndex={activeVideoIndex[carouselIndex] || 0}
                            vide0_id={short._id}
                            isSubtitle={isSubtitle}
                            currentPlayingVideoRef={currentPlayingVideoRef}
                            carouselIndex={carouselIndex}
                            isManuallyPaused={isManuallyPaused}
                            setIsManuallyPaused={setIsManuallyPaused}                        
                          />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                    <SideActions
                      data={short}
                      handleShow={handleShow}
                      setIsSubtitle={setIsSubtitle}
                      isSubtitle={isSubtitle}
                      shortContainerRef={shortContainerRef}
                      fullscreenState={fullscreenState}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
      </section>
      {isLoadingMore && !fullscreenState && <Spinner />}
      <div
        className={` ${
          fullscreenState ? "navigation-container-fullScreen" : "navigation-container"
        }`}
      >
        <div onClick={handleArrowUp} className="nav-up">
          <ion-icon name="arrow-up-outline"></ion-icon>
        </div>
        <div onClick={handleArrowDown} className="nav-down">
          <ion-icon name="arrow-down-outline"></ion-icon>
        </div>
      </div>
      <SaveToCollection
        show={show}
        handleClose={handleClose}
        selectedSave={selectedSave}
        type="PlayBack"
        shortContainerRef={shortContainerRef}
      />
    </>
  );
}

export default ShortContainer;