import { useEffect, useRef, useState } from "react";
import Hls from "hls.js";
import { useInView } from "react-intersection-observer";
import { VideoviewAction } from "../Redux/Action/BrowseAction";
import { useDispatch } from "react-redux";

function Shorts({
  short,
  shortContainerRef,
  index,
  activeIndex,
  vide0_id,
  isSubtitle,
  handleToggleFullscreen,
  isManuallyPaused,
  setIsManuallyPaused
}) {
  const dispatch = useDispatch();
  const videoRef = useRef(null);
  const hls = useRef(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [hasViewed, setHasViewed] = useState(false);
  const [hasSkipped, setHasSkipped] = useState(false);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [loadPercentage, setLoadPercentage] = useState(0);

  const { ref: observerRef, inView } = useInView({
    threshold: 0.8,
    root: shortContainerRef.current,
    triggerOnce: false,
    rootMargin: "-50px 0px",
  });

  useEffect(() => {
    if (!videoRef.current) return;

    if (Hls.isSupported()) {
      hls.current = new Hls({
        maxBufferLength: 10,
        maxMaxBufferLength: 20,
        backBufferLength: 30,
        lowLatencyMode: true,
        enableWorker: true,
        liveSyncDuration: 1,
      });
      hls.current.loadSource(short.streaming_url);
      hls.current.attachMedia(videoRef.current);

      hls.current.on(Hls.Events.MANIFEST_PARSED, () => {
        if (isSubtitle && short?.caption_url) {
          const track = document.createElement("track");
          track.kind = "subtitles";
          track.src = short.caption_url;
          track.srclang = "en";
          track.label = "English";
          track.default = true;
          videoRef.current.appendChild(track);
        }
        if (inView && !isManuallyPaused) {
          playVideo();
        }
      });

      hls.current.on(Hls.Events.ERROR, (event, data) => {
        console.error("HLS.js error:", data);
      });

      hls.current.on(Hls.Events.FRAG_LOADING, () => {
        setLoadPercentage(10);
      });

      hls.current.on(Hls.Events.FRAG_LOADED, () => {
        setLoadPercentage(50);
      });
    } else if (videoRef.current.canPlayType("application/vnd.apple.mpegurl")) {
      videoRef.current.src = short.streaming_url;
      if (inView && !isManuallyPaused) {
        playVideo();
      }
    }

    return () => {
      if (hls.current) {
        hls.current.stopLoad();
        hls.current.detachMedia();
        hls.current.destroy();
        hls.current = null;
      }

      if (videoRef.current) {
        videoRef.current.src = "";
        const tracks = videoRef.current.querySelectorAll("track");
        tracks.forEach((track) => track.remove());
      }
    };
  }, [short.streaming_url, isSubtitle, short?.caption_url]);

  // Handle play/pause based on visibility and manual pause state
  useEffect(() => {
    if (!videoRef.current) return;

    if (inView) {
      if (!isManuallyPaused) {
        playVideo();
        if (isMuted) {
          setIsMuted(false);
          videoRef.current.muted = false;
        }
      }
    } else {
      pauseVideo();
    }
  }, [inView, isManuallyPaused]);

  // Pause other videos when this one becomes active
  useEffect(() => {
    if (!shortContainerRef.current || !videoRef.current) return;

    if (inView && !isManuallyPaused) {
      const videos = Array.from(shortContainerRef.current.querySelectorAll("video"));
      videos.forEach((video) => {
        if (video !== videoRef.current) {
          video.pause();
          video.currentTime = 0; // Reset other videos when they lose focus
        }
      });
    }
  }, [inView, activeIndex, isManuallyPaused]);

  // Spacebar keydown event listener
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 32 && inView) {
        event.preventDefault();
        handlePlayPauseClick();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [isPlaying, inView]);

  const playVideo = () => {
    if (videoRef.current) {
      videoRef.current
        .play()
        .then(() => {
          setIsPlaying(true);
          setIsManuallyPaused(false);
        })
        .catch((error) => {
          console.error("Autoplay failed. User interaction required.", error);
          setIsPlaying(false);
        });
    }
  };

  const pauseVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      setIsPlaying(false);
      // Do NOT reset currentTime here; let it stay at the current position
    }
  };

  const handlePlayPauseClick = () => {
    if (isPlaying) {
      pauseVideo();
      setIsManuallyPaused(true);
    } else {
      playVideo();
      setIsManuallyPaused(false);
    }
  };

  const handleMuteToggle = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  const handleProgress = () => {
    if (videoRef.current?.currentTime >= 0.43 && !hasSkipped) {
      // handleSkipVideo();
    }
    if (videoRef.current?.currentTime >= 8 && !hasViewed) {
      onViewed();
    }
  };

  const onViewed = async () => {
    const payload = { videoId: vide0_id };
    const res = await dispatch(VideoviewAction(payload));
    if (res.payload.statusCode) {
      setHasViewed(true);
      console.log("View count incremented");
    }
  };

  const handleVideoCanPlay = () => {
    setIsVideoReady(true);
    setLoadPercentage(100);
  };

  const handleVideoError = () => {
    setIsVideoReady(true);
    setLoadPercentage(0);
    console.error("Video failed to load");
  };

  const handleVideoProgress = (e) => {
    if (!videoRef.current || isVideoReady) return;
    const buffered = videoRef.current.buffered;
    if (buffered && buffered.length > 0) {
      const bufferedEnd = buffered.end(buffered.length - 1);
      const duration = videoRef.current.duration || 100;
      const percentage = Math.min(
        Math.round((bufferedEnd / duration) * 100),
        99
      );
      setLoadPercentage(percentage);
    }
  };

  return (
    <div
      className={`video ${isSubtitle ? "subtitles-visible" : "subtitles-hidden"}`}
      ref={observerRef}
      style={{ width: "720px", margin: "0 auto" }}
    >
      <video
        ref={videoRef}
        muted={isMuted}
        loop
        playsInline
        preload="auto"
        width="720px"
        height="auto"
        onTimeUpdate={handleProgress}
        onCanPlay={handleVideoCanPlay}
        onProgress={handleVideoProgress}
        onError={handleVideoError}
        controls={false}
        className="video-player"
        crossOrigin="anonymous"
        style={{
          "--cue-position": "bottom 10%",
        }}
      >
        <source src={short.streaming_url} type="application/vnd.apple.mpegurl" />
        {isSubtitle && short?.caption_url && (
          <track
            kind="subtitles"
            src={short?.caption_url}
            srcLang="en"
            label="English"
            default
          />
        )}
      </video>

      {!isVideoReady && (
        <div className="video-loader">
          <div className="circular-loader">
            <svg className="progress-ring" width="100" height="100">
              <circle
                className="progress-ring__circle"
                stroke="#fff"
                strokeWidth="8"
                fill="transparent"
                r="44"
                cx="50"
                cy="50"
                style={{
                  strokeDasharray: "276.46",
                  strokeDashoffset: 276.46 - (276.46 * loadPercentage) / 100,
                }}
              />
            </svg>
            <span className="percentage-text">{loadPercentage}%</span>
          </div>
        </div>
      )}

      <div className="controls" onClick={(e) => e.stopPropagation()}>
        <span onClick={handlePlayPauseClick}>
          <ion-icon name={`${isPlaying ? "pause" : "play"}-outline`}></ion-icon>
        </span>
        <div className="d-flex gap-4">
          <span onClick={handleMuteToggle}>
            <ion-icon name={`volume-${isMuted ? "mute" : "medium"}-outline`}></ion-icon>
          </span>
          <span onClick={handleToggleFullscreen}>
            <ion-icon name="scan-outline"></ion-icon>
          </span>
        </div>
      </div>

      <div
        onClick={(e) => {
          e.stopPropagation();
          handlePlayPauseClick();
        }}
        className={`play-pause ${isPlaying ? "" : "show-play-pause"}`}
      >
        <ion-icon name="play-outline"></ion-icon>
      </div>
    </div>
  );
}

export default Shorts;